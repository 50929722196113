import React from 'react'
import Styled from 'styled-components'
import Feed from '../../component/Feed/Feed'
import Widgets from '../../component/Widgets/Widgets'
import CreationIcon from 'mdi-react/CreationIcon'
import {FeedMoreOption} from '../../component/Feed/Feed'
import { useEffect } from 'react'
import { useState,useCallback } from 'react'
import {debounce} from 'lodash'
import { createRef } from 'react'
import { useRef } from 'react'
import TwitterForm from '../../component/TwitterForm/TwitterForm'
import styled from 'styled-components'
import { useContext } from 'react'
import { FeedContext } from '../../Context/FeedDataContext'



export const HomeLayout = Styled.div`
    display:grid;
    grid-template-columns:1.7fr 1fr;
    z-index:0; 


`


export const FeedLayout = Styled.main`
    box-siz:border-box;
    border-right:1px solid ${props=>props.theme.feedOutline};
    & > div:nth-child(3){
        border-top: 1px solid #37444d;
        margin-top: 10px;
    }
`
export const Heading = Styled.div`
color:${props=>props.theme.text};
background-color:${props=>props.theme.bgColor};

padding:15px 10px;
font-size:1.2rem;
font-weight:bold;
border-bottom:1px solid ${props=>props.theme.feedOutline};
display:flex;
align-items:center;
position:sticky;
top:0px;
z-index:100;

`

const RelativeBox = styled.div`
    border-bottom: ${props => props.drawBottomLine?'1px':'0px'} solid  ${props => props.theme.feedOutline};
    padding-bottom:${props => props.drawBottomLine?'10px':'0'};

`



const Home = () => {
    const {Tweets} = useContext(FeedContext)
    return (
        <HomeLayout>
                <FeedLayout>
                    <Heading>Home 
                        <FeedMoreOption width="40px" height="40px">
                             <CreationIcon/>
                        </FeedMoreOption>
                    </Heading>
                    <TwitterForm/>
                    {
                         Tweets.map((r,i)=>(
                            <Feed key={`r-${r.id}-${i}`} 
                            data={r}
                            />
                        ))
                    }
                   
                    {/* <LinkPreview/> */}
                </FeedLayout>
               <Widgets/>
        </HomeLayout>
    )
}

export default React.memo(Home)

const Form = ()=>{
    const [Input, setInput] = useState("")
    const ref = useRef();
    let r = new RegExp(/#\w+/gmi);
    
    const handler = useCallback(debounce((e)=>{
        let l = e.length;
        setInput(e);
        var tag = document.getElementById('editable');
        let node = tag.childNodes[tag.childNodes.length-1];
        let offset = node.textContent.length-1 
        var setpos = document.createRange();
        var set = window.getSelection();
        setpos.setStart(node,offset);
        setpos.collapse(true);
        set.removeAllRanges();
        set.addRange(setpos);
        tag.focus()
    },1000), []);
    
    function c(event){
        try{

        
        event.currentTarget.selectionStart = 0 
    event.currentTarget.selectionEnd = 0
        var parts = event.currentTarget.textContent.split(" ");
        parts.forEach((element,i) => {
           if(r.test(element)){
            parts[i] = <><span style={{color:"red",cursor:"pointer"}} key={i}>{parts[i]}</span>&nbsp;</>;     
           }else{
               parts[i] = " "+parts[i]+" "
           } 
        });
        parts.join(" ")
        handler(parts);
    }catch(e){
        console.error('input error',e);
    }
    }
    return (
        <div
        ref={ref}
        style={{backgroundColor:"#ffffff",height:"400px",padding:"10px"}}
        id="editable"
        contentEditable="true"
        suppressContentEditableWarning="true"
        onInput={e=>c(e)}
      >{Input}
      </div>
       
    )
}


const LinkPreview = ({})=>{

    useEffect(() => {
        fetch('http://localhost:5055/')
        .then(response => response.text())
        .then(data => {
            let s = data.split("<meta").filter(f=>f.indexOf("name=")!=-1);
            let s2 = data.split("<meta").filter(f=>f.indexOf("property=")!=-1);
            let full = [...s,...s2].filter(f=>f.indexOf("content")!==-1);
            let all = [];
            full.forEach(w=>{
                all.push(w.trim())
            })
            console.log('xxxxxxxxxxxxxxx',all)



        });
    }, [])


    return (
        <div>Hi</div>
    )
}



