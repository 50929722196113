import React, { useContext, } from 'react'
import styled, {ThemeProvider} from 'styled-components'
import { IoClose } from 'react-icons/io5'
import {Button} from '../Profile/Profile'
import { useHistory } from 'react-router-dom'
import { FeedMoreOption } from '../../component/Feed/Feed'

const ModalOverlay = styled.div`
    width:100vw;
    height:100vh;
    position:fixed;
    z-index:9999;
    background-color:${props=>props.theme.modalOverlayBg};
    color:white;
    display:flex;
    top:0px;
    left:0px;

`;

const ModalBody = styled.div`
    width:600px;
    margin:40px auto auto auto;
    background-color:${props=>props.theme.bgColor};
    border-radius:15px;

`;

const ModalHeader = styled.div`
    height:50px;
    display:flex;
    align-items:center;
    padding-left:5px;
    border-bottom:1px solid ${props=>props.theme.feedOutline};
    color:${props=>props.theme.accent};
    svg{
        cursor:pointer;
    }
    & span:nth-child(2){
        color:${props=>props.theme.text};
        font-weight:bold;
        font-size:1rem;
        margin-left:20px;
    }
    button{
        margin-left:auto;
        margin-right:10px;
    }
`;






export const Modal = ({
    title,
    children,
    handler,
})=>{
    let history = useHistory();

  let back = e => {
    e.stopPropagation();
    history.goBack();
  };
    return (
        <ModalOverlay onClick={back} >
            <ModalBody onClick={e=>e.stopPropagation()}>
                <ModalHeader>
                    <FeedMoreOption toLeft="0px"  width="40px" height="40px">
                        <IoClose size="30" onClick={back} />
                    </FeedMoreOption>
                    {title && <span>Edit Profile</span> } 
                    {handler && <Button width="80px" solid={true} onClick={handler} >Save</Button>}
                </ModalHeader>
                {children}
            </ModalBody>
        </ModalOverlay>
    )
}

