import React from 'react'
import Styled from 'styled-components'
import SearchIcon from 'mdi-react/SearchIcon'
import { Link } from 'react-router-dom';

const SearchInputWrapper = Styled.div`
    padding:10px 0;
    width:100%;
    position:sticky;
    top:0px;
    z-index:99;
    background:${props => props.theme.bgColor};
`;

const SearchInputBox = Styled.div`
    display:flex;
    background:${props => props.theme.widgetSearchBg};
    padding:10px;
    border-radius:50px;
    align-items:center;
    & svg{
        fill:${props => props.theme.textMuted}
    }
`;

const SearchInput = Styled.input`
    background:transparent;
    flex:1;
    height:20px;
    border:none;
    outline:none;
    padding-left:10px;
    // font-size:1rem;
    color:${props => props.theme.text};

    &::placeholder{
        color:${props => props.theme.textMuted}
    }
    
`;

const WidegetLayout = Styled.section`
   padding:0 20px; 
   poisition:relative;
   z-index:99;

`


const WhatsHappning = Styled.div`
    background:${props => props.theme.widgetSearchBg};
    border-radius:20px;
    position:sticky;
    top:-490px;

h3{
    color:${props => props.theme.text};
    padding:1rem 1rem 0 1rem;
}


`;

const WhoToFollow = Styled.div`
    background:${props => props.theme.widgetSearchBg};
    border-radius:20px;
    position:sticky;
    top:${props=> props.showTop || '155px'};

h3{
    color:${props => props.theme.text};
    padding:1rem 1rem 0 1rem;
}


`;

const WhatsHappningTile = Styled.div`
display:flex;
padding:10px;
border-top:1px solid ${props => props.theme.feedOutline};
border-bottom:1px solid ${props => props.theme.feedOutline};
align-items:center;


`;

const TileContent = Styled.div`

`;

const TileTitle = Styled.div`

    color:${props => props.theme.textMuted};
    font-size:0.8rem;


`;

const TileText = Styled.div`
color:${props => props.theme.text};
font-weight:bolder;
margin-top:10px;

`;

const TileImage = Styled.div`
    display:flex;
    img{
        object-fit:cover;
        width:100%;
        border-radius:10px;
    }

`;

const BlankSpace = Styled.div`
    position:sticky;
    background:${props => props.theme.bgColor};
    width:100%;
    height:60px;
    top:0px;
    z-index:50;
`;

const ShowMore = Styled.div`
padding:20px 10px;

a{
    color:${props => props.theme.accent};
    text-decoration:none;
}

`;


const Widgets = ({ showSearch = true, showWhats = true, showWho = true,showTop=null}) => {
    return (
        <WidegetLayout>
            {
                showSearch && (
                    <SearchInputWrapper>
                        <SearchInputBox>
                            <SearchIcon />
                            <SearchInput placeholder="Search Twiitter" />
                        </SearchInputBox>
                    </SearchInputWrapper>
                )
            }

            {
                showWhats && (
                    <WhatsHappning>
                        <h3><b>What's happening</b></h3>
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <ShowMore>
                            <Link to="/">Show More</Link>
                        </ShowMore>
                    </WhatsHappning>
                )
            }

            {
                showWho && (
                    <WhoToFollow showTop={showTop} >
                        <h3><b>Who to fellow</b></h3>
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <WhatHappningTile />
                        <ShowMore>
                            <Link to="/">Show More</Link>
                        </ShowMore>
                    </WhoToFollow>
                )
            }



        </WidegetLayout>
    )
}

export default Widgets

const WhatHappningTile = () => {
    return (
        <WhatsHappningTile>
            <TileContent>
                <TileTitle>
                    IPL 2020 . LIVE</TileTitle>
                <TileText>
                    IPL: Delhi Capitals take on Sunrisers Hyderabad
            </TileText>
            </TileContent>
            <TileImage>
                <img src={`https://picsum.photos/seed/Use${Math.random() * 100}rAvatar/100/100`} alt="pic" />
            </TileImage>
        </WhatsHappningTile>
    )
}
