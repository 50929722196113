import React, { useContext } from 'react'
import './index.scss'
import Styled, {createGlobalStyle}  from 'styled-components'
import ThemeContextProvider, {ThemeContext} from '../../Context/ThemeContext'
import { BrowserRouter, Route,Switch, useLocation,useHistory, Redirect } from 'react-router-dom'
import { Sidebar } from '../../component/Sidebar/Sidebar'
import styled, {ThemeProvider} from 'styled-components'
import Home from '../Home/Home'
import Explore from '../Explore/Explore'
import LightFont from '../../fonts/HelveticaNeueLTStd-Lt.otf'
import BoldFont from '../../fonts/HelveticaNeueLTStd-Bd.otf'
import TwitterForm from '../../component/TwitterForm/TwitterForm'
import { IoClose } from 'react-icons/io5'
import Profile from '../Profile/Profile'
import UpdateProfile from '../Setting/UpdateProfile'
import DisplaySettings from '../Setting/DisplaySettings'


const GlobalStyleSheet =  createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&family=Roboto:wght@300;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;900&display=swap');

@font-face {
    font-family: "Helvetica";
    font-weight: normal;
    src:url(${LightFont}) format("opentype");
  }
  
  @font-face {
    font-family: "Helvetica";
    font-weight: bold;
    src:url(${BoldFont}) format("opentype");
  }

  *{
    // font-family: "Helvetica" !important;
    font-family: 'Open Sans', sans-serif;
    // font-family: 'Roboto', sans-serif;
    // font-family: 'Poppins', sans-serif;

  }



    html,body {
        padding:0;
        margin:0;
        background-color:${props => props.theme.bgColor};
    }

`


const MainGrid = Styled.div`
            max-width:1250px;
            margin:auto;
            display:grid;
            grid-template-columns:1fr 4fr;
            height:100vh; 

`;

const SidebarLayout = Styled.aside`
border-right:1px solid ${props=>props.theme.feedOutline};

`


const ComposeTweet = styled.div`
    width:100vw;
    height:100vh;
    position:fixed;
    z-index:9999;
    background-color:${props=>props.theme.modalOverlayBg};
    color:white;
    display:flex;
    top:0px;
    left:0px;

`;



const Index = () => {
    let username = "vinodkaradiya"
    const  location = useLocation();
    
    const {CurrentTheme} = useContext(ThemeContext);

    let background = location.state && location.state.background;



    return (
        <React.Fragment>
           <ThemeProvider theme={CurrentTheme}>

            <GlobalStyleSheet />
                <MainGrid>
                    <SidebarLayout>
                        <Sidebar/>
                    </SidebarLayout>
                    <Switch location={background || location }>

                       
                        <Route exact path="/home" >
                            <Home/>
                        </Route>
                        <Route exact path="/explore" >
                            <Explore/>
                        </Route>
                        <Route exact path="/notification" >
                            <Notification />
                        </Route>
                        <Route exact path={`/${username}`}>
                            <Profile />
                        </Route>
                        <Route  path="*">
                            <Redirect to="/home" />
                        </Route>
                    
                    </Switch>
                    {background && <Route exact path="/compose/tweet" children={ <Compose/>} />}
                    {background && <Route exact path="/settings/profile" children={ <UpdateProfile/>} />}
                    {background && <Route exact path="/i/display" children={ <DisplaySettings/>} />}
                   
                </MainGrid>
           </ThemeProvider>
        </React.Fragment>
    )
}

export default Index





const Notification = ()=>{
    return <h2>Notification</h2>
}

const Compose = ()=>{
    let history = useHistory();

  let back = e => {
    e.stopPropagation();
    history.goBack();
  };
    return (
        <ComposeTweet onClick={back} >
            <TweetModalBody onClick={e=>e.stopPropagation()}>
                <TweetModalHeader>
                    <IoClose size="30" onClick={back} />
                </TweetModalHeader>
                <TwitterForm radius="20px" />
            </TweetModalBody>
        </ComposeTweet>
    )
}

const TweetModalBody = styled.div`
    width:600px;
    margin:40px auto auto auto;
    background-color:${props=>props.theme.bgColor};
    border-radius:20px;

`;

const TweetModalHeader = styled.div`
    height:50px;
    display:flex;
    align-items:center;
    padding-left:20px;
    border-bottom:1px solid ${props=>props.theme.feedOutline};
    color:${props=>props.theme.accent};
    svg{
        cursor:pointer;
    }
`;