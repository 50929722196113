import React,{useState} from 'react'
import {Editor, EditorState,CompositeDecorator,} from 'draft-js';
import {RiEarthFill,RiUserFollowLine,RiBarChartHorizontalFill} from 'react-icons/ri'
import {IoEarthOutline} from 'react-icons/io5'
import {GoMention} from 'react-icons/go'
import {TiTick} from 'react-icons/ti'
import 'draft-js/dist/Draft.css';

import {ImFilePicture} from 'react-icons/im'
import {AiOutlineFileGif} from 'react-icons/ai'
import {VscSmiley} from 'react-icons/vsc'
import {IoCalendarOutline} from 'react-icons/io5'
import {BiCheck} from 'react-icons/bi'
import { FeedMoreOption, UserAvatar } from '../Feed/Feed';
import styled from 'styled-components';
import { useEffect } from 'react';


/**
       * Super simple decorators for handles and hashtags, for demonstration
       * purposes only. Don't reuse these regexes.
       */
      const HANDLE_REGEX = /@[\w]+/g;
      const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;

      function handleStrategy(contentBlock, callback, contentState) {
        findWithRegex(HANDLE_REGEX, contentBlock, callback);
      }

      function hashtagStrategy(contentBlock, callback, contentState) {
        findWithRegex(HASHTAG_REGEX, contentBlock, callback);
      }

      function findWithRegex(regex, contentBlock, callback) {
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
          start = matchArr.index;
          callback(start, start + matchArr[0].length);
        }
      }

      const HandleSpan = (props) => {
        return (
          <span
            style={styles.handle}
            data-offset-key={props.offsetKey}
          >
            {props.children}
          </span>
        );
      };

      const HashtagSpan = (props) => {
        return (
          <span
            style={styles.hashtag}
            data-offset-key={props.offsetKey}
          >
            {props.children}
          </span>
        );
      };

      const styles = {
        root: {
          fontFamily: '\'Helvetica\', sans-serif',
          padding: 20,
          width: 600,
        },
        editor: {
          border: '1px solid #ddd',
          cursor: 'text',
          fontSize: 16,
          minHeight: 40,
          padding: 10,
        },
        button: {
          marginTop: 10,
          textAlign: 'center',
        },
        handle: {
          color: 'rgba(98, 177, 254, 1.0)',
          direction: 'ltr',
          unicodeBidi: 'bidi-override',
        },
        hashtag: {
          color: 'rgba(95, 184, 138, 1.0)',
        },
      };

      const compositeDecorator = new CompositeDecorator([
        {
          strategy: handleStrategy,
          component: HandleSpan,
        },
        {
          strategy: hashtagStrategy,
          component: HashtagSpan,
        },
      ]);
const FormWrapper = styled.div`
      background-color:${props=>props.theme.bgColor};
      display:flex;
      box-sizing: border-box;
      padding: 10px;
      padding-bottom:0;
      border-bottom:1px solid ${props=>props.theme.feedOutline};
      position:relative;
      z-index:10;
      border-radius:${props=>props.radius||"0px"};


`;

const TweetFromWrapper = styled.div`
      background-color:${props=>props.theme.bgColor};
      padding:10px;
      padding-bottom:0;
      width:100%;
      border-radius:${props=>props.radius||"0px"};

`;

const AttachementWrapper = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    border-top:1px solid ${props=>props.theme.feedOutline};
    ul{
      display:flex;
      list-style:none;
      padding:0;  
      li{
        margin-right:10px;
      }
    }
    button{
      margin-left:auto;
      width:100px;
      height:30px;
      background-color:${props=>props.theme.accent};
      border:none;
      color:#ffffff;
      font-weight:bold;
      border-radius:20px;
      display:flex;
      align-items:center;
      justify-content:center;
      &:disabled{
      background-color:${props=>props.theme.accentDisabled};
      color:${props=>props.theme.textMuted};
      
      
      }
    }
`;

const TweetPrivacyCTA = styled.div`
    background-color:${props=>props.theme.bgColor};

`;

const TweetPrivacyCTAButton = styled.div`
  background-color:${props=>props.theme.bgColor};
  margin-top: 20px;  
  margin-bottom: 20px;  
  div{
    display: flex;
    align-items:center;
    line-height:normal;
    width:fit-content;
    padding:2px 5px;
    border-radius:10px;
  color:${props=>props.theme.accent};
  font-weight:bold;
  font-size:12px;
  svg{
    margin-right:5px;
    width:20px;
    height:20px;
  }
    &:hover{
      background-color:${props=>props.theme.accentHover};
    }
  }
  


  

`;

const TweetPrivacyCTAModal = styled.div`
    position:absolute;
    z-index:999;
    left:0px;
    width:300px;
    background-color:${props=>props.theme.bgColor};
    color:${props=>props.theme.text};
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow:0px 0px 10px 0px ${props=>props.theme.modalShadow};
    overflow:hidden;
    p{
      padding:10px 10px 0px 10px;
      display:flex;
      flex-direction:column;
      span{
    color:${props=>props.theme.textMuted};
    margin-top:5px;
    font-size:15px;
       } 
    }

`;

const TweetPrivacyCTAOptionList = styled.ul`
    margin:0 0 10px 0;
    list-style:none;
    padding:0;

    li{
      cursor:pointer;
      &:hover{
        background-color:${props=>props.theme.accentHover};
      }
      height:50px;
      display:flex;
      align-items:center;
      padding:0px 10px;
      & svg:first-child{
      background-color:${props=>props.theme.accent};
      height:25px;
      width:25px;
      padding:5px;
      border-radius:20px;
      margin-right:10px;
        
      }
      & svg:last-child{
        margin-left:auto;
        height:25px;
        width:25px;
      }

    }
`


const TwitterForm = ({radius}) => {
    const [editorState, setEditorState] = useState(() =>EditorState.createEmpty(compositeDecorator),);
    
    

    return (
      <FormWrapper radius={radius}>
        <UserAvatar>
        <img src="https://picsum.photos/seed/vinod/50/50" alt="img"/>
        </UserAvatar>
      <TweetFromWrapper radius={radius}>
          <Editor 
          placeholder="What's happening?"
          editorState={editorState} 
          onChange={setEditorState}
          stripPastedStyles={true}
        />
        <TweetPrivacy/>
        <AddAttachement/>
      </TweetFromWrapper>
      </FormWrapper>
    )
}

export default TwitterForm




const TweetPrivacy = ()=>{
    const REPLAY_BY = [
        {id:"r1","value":1,"text":"Everyone",Icon:IoEarthOutline,SIcon:RiEarthFill},
        {id:"r2","value":2,"text":"People you follow",Icon:RiUserFollowLine},
        {id:"r3","value":3,"text":"Only people you mention",Icon:GoMention},
    ] 
    const [reply,setreply] =  useState(REPLAY_BY[0])
    const [showOptions, setshowOptions] = useState(false)


    useEffect(()=>{
      let dropParant =  document.getElementById('PrivacyOptionID');
  
      document.addEventListener('click',(e)=>{
        setshowOptions(dropParant?.contains(e.target))
      })
  
      return ()=>{
        document.removeEventListener('click',null)
      }
  },[])

    const handler = (r)=>{
        setreply(r);
        setshowOptions(false);
    }
    return <TweetPrivacyCTA id="PrivacyOptionID">
        <TweetPrivacyCTAButton onClick={e=>setshowOptions(true)}>
        <div>{<reply.Icon/>} <span>{reply.text}</span> can reply</div>
        </TweetPrivacyCTAButton>
        {
            showOptions && 
        <TweetPrivacyCTAModal >
          <p>
            <b>Who can reply?</b>
            <span>
            People you choose can add others to the conversation by mentioning them.
            </span>
          </p>
            
    <TweetPrivacyCTAOptionList>
        {
            REPLAY_BY.map((r)=>(
                <li onClick={e=>handler(r)} key={r.id} >{<r.Icon/>} <span>{r.text}</span>  {reply.value===r.value&& <BiCheck/>} </li>
            ))
        }
       
    </TweetPrivacyCTAOptionList>
        </TweetPrivacyCTAModal>
        }
   </TweetPrivacyCTA>
}

const AddAttachement = ()=>{
    return (
          <AttachementWrapper>
            <ul>
            <li>
                <FeedMoreOption width="40px" height="40px" >
                <ImFilePicture size="20" />
                </FeedMoreOption>
                </li>
            <li>
            <FeedMoreOption width="40px" height="40px" >
              <AiOutlineFileGif size="20"/>
            </FeedMoreOption>
            </li>
            <li>
            <FeedMoreOption width="40px" height="40px" >
              <RiBarChartHorizontalFill size="20"/>
            </FeedMoreOption>
            </li>
            <li>
            <FeedMoreOption width="40px" height="40px" >
              <VscSmiley size="20"/>
            </FeedMoreOption>
            </li>
            <li>
            <FeedMoreOption width="40px" height="40px" >
              <IoCalendarOutline size="20"/>
            </FeedMoreOption>
            </li>
            </ul>
            <button disabled={true} >Tweet</button>
        </AttachementWrapper>
    )
}
