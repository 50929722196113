import React,{useState} from 'react'
import Styled from 'styled-components'
import ReTweetIcon from 'mdi-react/TwitterRetweetIcon'

import ChevronDwonIcon from 'mdi-react/ChevronDownIcon'

import CommentOutline from 'mdi-react/CommentOutlineIcon'

import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon'
import HeartsIcon from 'mdi-react/HeartIcon'

import ShareIcon from 'mdi-react/ArrowExpandUpIcon'
import styled from 'styled-components'

import Moment from 'moment'


const FeedLayout = Styled.div`
    padding:10px 10px 1rem 10px;
    border-bottom:1px solid ${props => props.theme.feedOutline};
    width:auto;
    cursor:pointer;
    &:hover{
        background-color:${props => props.theme.feedHoverBg};
    }
    
    
`;



const Retwiited = Styled.div`
    display:flex;
    align-items:center;
    padding-left:30px;
    svg{
        margin-right:5px;
        fill:${props => props.theme.textMuted}
    }
    span{
        font-size:0.8rem;
        font-weight:bold;
        color:${props => props.theme.textMuted}
    }
    

`;

const FeedBox = Styled.div`
    display:flex;
    margin-top:0px;
`;



export const UserAvatar = Styled.div`
    width:50px;
    margin-right:10px;
    img{
        width:100%;
        border-radius:50px;
    }
`;

const FeedInfo = Styled.div`
flex:1
`;

const UserDetails = Styled.div`
display:flex;
align-items:center;
flex:1;
`;

const UserDetailsName = Styled.span`
    font-weight:bolder;
    color:${props => props.theme.text};
    

`;

const UserDetailsUserName = Styled.span`
    margin:0 10px;
    color:${props => props.theme.textMuted}
`;

const FeedTime = Styled.span`
color:${props => props.theme.textMuted}
`;

/**
 * 
 * @param width => deifne width
 * @param height => deifne height
 * @param toLeft => deifne left margin || auto
 * @param toRight => deifne left margin || auto
 * @param fill => deifne svg fill || textMuted
 * 
 */
export const FeedMoreOption = Styled.span`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left:${props=>props.toLeft||"auto"};
    margin-right:${props=>props.toRight||"0"};
    width:${props=>props.width||"30px"};
    height:${props=>props.height||"30px"};
    border-radius:50px;
    cursor:pointer;
    svg{
        fill:${props => props.fill || props.theme.textMuted};
        color:${props => props.fill || props.onhoverFill || ""};
    }
    &:hover{
        background-color:${props => props.onhoverFillBg || props.theme.accentHover};
        svg{
        fill:${props => props.onhoverFill || props.theme.accent};
        }
    }
`;

const FeedContent = Styled.div`
color:${props => props.theme.text};
`;

const HashTag = Styled.span`
    display:block;
    color:${props => props.theme.accent};
    padding-right:10px;
`;

const FeedAction = Styled.div`
    margin-top:5px;
    flex:1;
    display:flex;
    justify-content:space-between;
`;

const FeedActionItem = Styled.div`
    display:flex;
    flex:1;
    align-items:center;
    color:${props=> props.onFill || props.theme.textMuted};
    font-size:0.8rem;
    &:hover{
        color:${props=> props.onhoverFill || props.theme.accent};
    }

`;

const FeedWrapper = Styled.div`
    flex:1;
`;

const FeedImageWrapper = styled.div`
    display:flex;
    width:550px;
    height:300px;
    margin-top:20px;
    flex-wrap: wrap;
    border-radius:20px;
    overflow:hidden;
    align-items: stretch;
    border:1px solid ${p=>p.theme.feedOutline}; 


`;

const FeedImageBox = styled.div`
    flex-basis:50%;
    flex-grow:1;
    height:${props=>props.height||"100%"};
    // width:${props=>props.width||"50%"};;
    overflow:hidden;
    // align-items: stretch;
    display:flex;
    align-items:center;
    justify-content:center;

`;

const abbreviate_number = (num, fixed) => {
    if (num === null) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }



const getTweetImages = (n)=>{
   let a = [...new Array(n).keys()]
   a.forEach((ti,i) => {
    a[i] = `https://picsum.photos/seed/${Math.round(Math.random()*Math.floor(123456789))}/${Math.round(2000/n)}/${Math.round(2000/n)}`
   })
   return a;
}

const getHeight = (n)=>{
    switch(n){
        case 1 : return "100%";
        case 2 : return "100%";
        default: return "50%";
    }
}

const getWidth = (n)=>{
    switch(n){
        case 1 : return "100%";
        case 2 : return "50%";
        default: return "50%";
    }
}

const getRandomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}


const Feed = ({data}) => {
    let TOTAL_IMG = getRandomNum(0,4);
    const FeedImages =  getTweetImages(TOTAL_IMG);
    return (
        <FeedLayout>
            <Retwiited><ReTweetIcon size="18" /> <span>Anamika Dube Retweeted</span> </Retwiited>
            <FeedBox>
                <UserAvatar>
                    <img src={`https://picsum.photos/seed/Use${Math.random() * 100}rAvatar/50/50`} alt="pic" />
                </UserAvatar>
                <FeedWrapper>
                    <FeedInfo>
                    <UserDetails>
                        <UserDetailsName>{data.first_name} {data.last_name}</UserDetailsName>
                        <UserDetailsUserName>@{data.username}</UserDetailsUserName>
                        <FeedTime>{data.time}</FeedTime>
                        <FeedMoreOption> <ChevronDwonIcon /> </FeedMoreOption>
                    </UserDetails>
                    <FeedContent>
                        {FormatText(data.text)}
                        <br />
                    </FeedContent>
                    {
                        FeedImages.length>0 && (
                            <FeedImageWrapper>
                                {
                                    FeedImages.map((img,i)=>(
                                        <FeedImageBox 
                                        height={getHeight(TOTAL_IMG)}   
                                        // width={getWidth(TOTAL_IMG)}   
                                        >
                                            <img src={img} alt={`twitter-img-${i}`} />
                                        </FeedImageBox>
                                        // <FeedImageBox>
                                        //     <img src={img} alt={`twitter-img-${i}`} />
                                        // </FeedImageBox>
                                    ))
                                }
                                {}
                            </FeedImageWrapper>
                        )
                    }
                </FeedInfo>
                <FeedAction>
                    <FeedActionItem>
                        <FeedMoreOption toLeft="0">
                        <CommentOutline size="18" />
                        </FeedMoreOption>
                        {abbreviate_number(parseInt(data.retweet)/1000000,0)}
                    </FeedActionItem>
                    
                    <FeedActionItem>
                        <FeedMoreOption toLeft="0">
                            <ReTweetIcon size="18" />
                        </FeedMoreOption>
                        {abbreviate_number(parseInt(data.retweet)/1000000,0)}
                    </FeedActionItem>
                    
                        <LikeTweet like={abbreviate_number(parseInt(data.likes)/100000,0)} />
                    
                    <FeedActionItem>
                    <FeedMoreOption toLeft="0">
                        <ShareIcon size="18" />
                        </FeedMoreOption>
                    </FeedActionItem>
            
            </FeedAction>
            </FeedWrapper>
            </FeedBox>

        </FeedLayout>
    )
}


export const LikeTweet = ({like})=>{
    const [Like, setLike] = useState(false)
    return <>
        {
                            !Like 
                     ? <FeedActionItem  onhoverFill="#e3555e">
                      <FeedMoreOption onhoverFillBg="#e3555e45" onhoverFill="#e3555e" toLeft="0">
                        <HeartOutlineIcon size="18" onClick={e=>setLike(true)} />
                        </FeedMoreOption>
                        {like}

                    </FeedActionItem>
                    :<FeedActionItem onFill="#e3555e"  onhoverFill="#e3555e">
                      <FeedMoreOption onhoverFillBg="#e3555e45" onhoverFill="#e3555e"  fill="#e3555e" toLeft="0">
                        <HeartsIcon size="18"  onClick={e=>setLike(false)} />
                    </FeedMoreOption>
                        {like}
                    </FeedActionItem>
                        }
    </>
}

export default Feed

const HashTagSpan = styled.span`
    color:${props=>props.theme.accent};
    cursor:pointer;
`

const FormatText = (p)=>{
    let parts = p.split(" ");
    // let r = new RegExp(/#\w+/gi);
    // if(r.test(element)){

    parts.forEach((element,i) => {
       if(element.split("")[0]==="#"){
        parts[i] = <HashTagSpan>{parts[i]}</HashTagSpan>;     
       }else{
           parts[i] = " "+parts[i]+" "
       } 
    });
    return parts;
}


