import React,{useContext} from 'react'
import { Modal } from '../Modal/Modal'
import {IoMdCheckmarkCircle} from 'react-icons/io'
import styled from 'styled-components'
import { ThemeContext } from '../../Context/ThemeContext';

const Background = styled.div`
    padding:1rem;
    span{
        font-size:0.9rem;
        font-weight:bold;
        color:${p=>p.theme.textMuted};
    }


`


const BackgroundThemeList = styled.ul`
    display:flex;
    margin:0;
    padding:0;
    list-style:none;
    justify-content:space-between;
    padding:10px;
`;

const BackgroundItem = styled.li`
    display:flex;
    flex:1;
    align-items:center;
    justify-content:space-evenly;
    height:60px;
    border:2px solid #eeeeee;
    border-radius:3px;
    cursor:pointer;
    span{
        width:20px;
        height:20px;
        border-radius:20px;
        display:flex;
        border:${p=>p.isActive?"0px":"2px"} solid #aab8c2;
        background-color:${p=>p.isActive?"#ffffff":""};
    }
    p{
        margin:0;
    }
    svg{
        margin:auto;
        fill:${p=>p.theme.accent};
        color:${p=>p.theme.accent};
    }
    &:nth-child(1){
        background:#ffffff;
        color:#000;
        border-color:${p=>p.theme.feedOutline};
    } 
    &:nth-child(2){
        background:#151f2b;
        color:#eeeeee;
        border-color:${p=>p.theme.feedOutline};
        margin:0 5px;

    },
    &:nth-child(3){
        background:#000000;
        color:#eeeeee;
        border-color:${p=>p.theme.feedOutline};

    }   
`;



const DisplaySettings = () => {
    const {changeTheme,CurrentThemeName} = useContext(ThemeContext)
    let ThemeOptionArr = [
        {key:"qawsed1234",name:"Default",value:"light"},
        {key:"23we34gty",name:"Dim",value:"dim"},
        {key:"3esde2346",name:"Lights out",value:"dark"},
    ]
    return (
       <Modal  title="Edit Profile"
        handler={()=>alert('saved')}
       >
           <Background>
            <span>Background</span>
            <BackgroundThemeList>
                {
                    ThemeOptionArr.map(t=>(
                        <BackgroundItem isActive={t.value === CurrentThemeName}  key={t.key} onClick={e=>changeTheme(t.value)}>
                            <span>
                                {t.value === CurrentThemeName && <IoMdCheckmarkCircle fontSize="20px" />}
                            </span>
                            <p>{t.name}</p>
                        </BackgroundItem>
                    ))
                }
            </BackgroundThemeList>
           </Background>
        
       </Modal>
    )
}

export default DisplaySettings
