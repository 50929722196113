import React from 'react'
import { Modal } from '../Modal/Modal'

const UpdateProfile = () => {
    return (
       <Modal title="Edit Profile"
        handler={()=>alert('saved')}
       >
           <h1>Profile Setting</h1>
       </Modal>
    )
}

export default UpdateProfile
