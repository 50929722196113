import Styled from 'styled-components'



export const HomeLayout = Styled.div`
    display:grid;
    grid-template-columns:1.7fr 1fr;
    z-index:0; 
`
export const ProfileLayout = Styled.div`
    display:grid;
    grid-template-columns:1.7fr 1fr;
    z-index:0; 
`




export const BorderBoxLayout = Styled.main`
    box-siz:border-box;
    border-right:1px solid ${props=>props.theme.feedOutline};
    & > div:nth-child(3){
        border-top: 1px solid #37444d;
        margin-top: 10px;
    }
`
export const Heading = Styled.div`
color:${props=>props.theme.text};
padding:15px 10px;
font-size:1.2rem;
font-weight:bold;
border-bottom:1px solid ${props=>props.theme.feedOutline};
display:flex;
align-items:center;

`

const RelativeBox = Styled.div`
    border-bottom: ${props => props.drawBottomLine?'1px':'0px'} solid  ${props => props.theme.feedOutline};
    padding-bottom:${props => props.drawBottomLine?'10px':'0'};

`





