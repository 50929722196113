import React from 'react'
import './index.scss'
import { Sidebar } from '../../component/Sidebar/Sidebar';
import Layout from '../layout'
import ThemeContextProvider from '../../Context/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import FeedDataContext from '../../Context/FeedDataContext';




function App() {
  return (
    <BrowserRouter>
      <ThemeContextProvider>
        <FeedDataContext>
          <Layout/>    
        </FeedDataContext>
      </ThemeContextProvider>
    </BrowserRouter>
  );
}

export default App;

