import React, { createContext,useState,useEffect } from 'react'
import {FakeTweets,HASHTAGS} from '../mockData/tweetData'



const getRandomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}


function getTweetData(){
    let NewTweets = JSON.parse(JSON.stringify(FakeTweets.slice(0,getRandomNum(0,50)))) ;
    NewTweets.forEach((f,i)=>{
        f.text = hashTagGenerator(f.text)
        if(i>getRandomNum(0,50)){
            f.liked = true;
        }
    })

    return NewTweets;
   
    
}

const hashTagGenerator = (f="")=>{
    let s = f.split(" ");
    let h = [
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
        getRandomNum(0,s.length-1),
    ]
    s.forEach((w,i)=>{
        if(h.includes(i)){
            s[i] = "#"+w;
        }
    })
    return s.join(" ");

}

export const FeedContext = createContext();


const FeedDataContext = ({children}) => {
    const [Tweets, setTweets] = useState(getTweetData())
    useEffect(() => {
        setInterval(()=>{
            let t = getTweetData();
            setTweets(t);
        },10000)
    }, [])


    return (
       <FeedContext.Provider value={{Tweets}}>
           {children}
       </FeedContext.Provider>
    )
}

export default FeedDataContext


 // let r = new RegExp(/#\w+/gi);
    // POST.slice(Math.random() * 250, Math.random() * 499,).trim().split(" ");
    // POST.forEach((element,i) => {
    //    if(r.test(element)){
    //     parts[i] = <span  style={{color:"red",cursor:"pointer"}} key={i}>{parts[i]}</span>;     
    //    }else{
    //        parts[i] = " "+parts[i]+" "
    //    } 
    // });
    // parts.join(" ")