import React from 'react'
import Styled, { withTheme } from 'styled-components'
import Feed from '../../component/Feed/Feed'
import Widgets from '../../component/Widgets/Widgets'
import CreationIcon from 'mdi-react/CreationIcon'
import {FeedMoreOption} from '../../component/Feed/Feed'
import { useEffect } from 'react'
import { useState,useCallback } from 'react'
import {debounce} from 'lodash'
import { createRef } from 'react'
import { useRef } from 'react'
import TwitterForm from '../../component/TwitterForm/TwitterForm'
import { ProfileLayout,BorderBoxLayout } from '../ScreenLayouts/ScreenLayouts'
import { IoArrowBack, IoArrowBackCircleSharp, IoLink } from 'react-icons/io5'
import { ImCalendar } from 'react-icons/im'
import {BsLink45Deg} from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { FeedContext } from '../../Context/FeedDataContext'


export const Heading = Styled.div`
color:${props=>props.theme.text};
background-color:${props=>props.theme.bgColor};
padding:15px 10px;
font-size:1.2rem;
font-weight:bold;
border-bottom:1px solid ${props=>props.theme.feedOutline};
display:flex;
align-items:center;
position:sticky;
top:0px;
z-index:10;


`

const ProfileHeader = Styled.div`
    background-image:url(${props=>props.bgUrl||"https://picsum.photos/seed/picsfdjkdhfbum/600/200"});
    background-size:cover;
    height:200px;
    width:100%;
    position:relative;
    display:flex;
`

const ProfileImage = Styled.div`
    width:140px;
    height:140px;
    display:flex;
    overflow:hidden;
    border-radius:100px;
    margin-top:auto;
    margin-bottom:-70px;
    margin-left:20px;
    border:5px solid ${props=>props.theme.bgColor};
    img:{
        width:100%;
        height:100%;
    }

`
export const Button = Styled.button`
    outline:none;
    background-color:${props=>props.solid?props.theme.accent:"transparent"};
    width:${props=>props.width || "120px"};
    height:${props=>props.height || "40px"};
    border:2px solid ${props=>props.theme.accent};
    color:${props=>props.solid?"#ffffff":props.theme.accent};
    text-decoration:none;
    font-weight:bolder;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:20px;
    cursor:pointer;
    &:hover{
        background-color:${props=>props.solid?"#1495f3bf":props.theme.accentHover};
    }

`; 

const FollowBar = Styled.div`
    height:30px;
    display:flex;
    flex-direction:row-reverse;
    padding:10px 20px;
    a{
        text-decoration:none;
    }
`;

const FlexBox = Styled.span`
        display:inline-flex;
        align-items:center;
        justify-content:space-between;
        svg{
            margin-right:5px;
        }
`;

const ProfileDetails = Styled.div`
    color:${props=>props.theme.textMuted};
    display:flex;
    flex-direction:column;
    padding:10px 20px;
    span{
        margin:5px 0px;
    }
    a{
            margin-right:20px;
            text-decoration:none;
            display:inline-flex;
            align-items:center;
            span{
                color:${props=>props.theme.accent};
            }
            svg{
                color:${props=>props.theme.textMuted};
                font-size:1.5rem;
                margin-right:5px;
            }
            &:hover span{
            text-decoration:underline;

            }
        }
`;

const HightLight = Styled.span`
    color:${props=>props.theme.text};
    font-weight:${props=>props.bold||"normal"};
    span &:last-child {
        margin-left:${props=>props.left || 0 };
    } 
    
`;

const ProfileTab = Styled.ul`
    margin:0;
    padding:0;
    display:flex;
    list-style:none;
    justify-content: space-evenly;
    border-bottom:1px solid ${props=>props.theme.feedOutline};


`;

const ProfileTabItem = Styled.li`
    flex:1;
    display:flex;
    justify-content:center;
    background-color:transparent;
    height:50px;
    align-items:center;
    border-bottom:2px solid ${props=>props.active?props.theme.accent:"transparent"};
    &:hover{
        background-color: ${props=>props.theme.accentHover};
    }
    cursor:pointer;
    font-weight:bold;
    color:${props=>props.active?props.theme.accent:props.theme.textMuted};
`


const ActivityTabs = ()=>{
    const [Tab, setTab] = useState(1)

    return (
        <ProfileTab>
                     <ProfileTabItem onClick={e=>setTab(1)} active={Tab===1}>Tweets</ProfileTabItem>
                     <ProfileTabItem onClick={e=>setTab(2)} active={Tab===2}>Tweets & replies</ProfileTabItem>
                     <ProfileTabItem onClick={e=>setTab(3)} active={Tab===3}>Media</ProfileTabItem>
                     <ProfileTabItem onClick={e=>setTab(4)} active={Tab===4}>Likes</ProfileTabItem>
        </ProfileTab>
    )
}


const Home = (props) => {
    const location = useLocation();
    const {Tweets} = useContext(FeedContext)
    return (
        <ProfileLayout>
                <BorderBoxLayout>
                    <Heading>
                        <FeedMoreOption fill={props.theme.accent} toLeft="0" toRight="10px" width="40px" height="40px">
                            <IoArrowBack size="20"/>
                        </FeedMoreOption>
                        Vinod 
                        <FeedMoreOption width="40px" height="40px">
                             <CreationIcon/>
                        </FeedMoreOption>
                    </Heading>
                   <ProfileHeader>
                       <ProfileImage>
                           <img src="https://picsum.photos/seed/vinodkaradiya/200/200" alt="userpic" />
                       </ProfileImage>
                   </ProfileHeader>
                   <FollowBar>
                       <Link to={{pathname:"/settings/profile",state: { background:location}}}>
                            <Button >Edit Profile</Button>
                       </Link>
                   </FollowBar>
                   <ProfileDetails>
                        <HightLight bold="bold">Vinod Karadiya</HightLight>
                        <span>@kradster</span>
                        <HightLight>i am who , who i am</HightLight>
                        <span>
                             <a href="https://twiitter.kradster.com"><BsLink45Deg fontSize="20px" /> <span>https://twiitter.kradster.com</span> </a>
                            <FlexBox>
                            <ImCalendar/> Joined June 2020 
                            </FlexBox>
                            </span>
                        <span>
                        <HightLight bold="bold">16</HightLight> Following  
                        <HightLight bold="bold" left="10px">3</HightLight> Followers  
                        </span>
                   </ProfileDetails>
                   <ActivityTabs/>
                   {
                         Tweets.map((r,i)=>(
                            <Feed key={`r-${r.id}-${i}`} 
                            data={r}
                            />
                        ))
                    }
                </BorderBoxLayout>
               <Widgets/>
        </ProfileLayout>
    )
}

export default React.memo(withTheme(Home))

const Form = ()=>{
    const [Input, setInput] = useState("")
    const ref = useRef();
    let r = new RegExp(/#\w+/gmi);
    
    const handler = useCallback(debounce((e)=>{
        let l = e.length;
        setInput(e);
        var tag = document.getElementById('editable');
        let node = tag.childNodes[tag.childNodes.length-1];
        let offset = node.textContent.length-1 
        var setpos = document.createRange();
        var set = window.getSelection();
        setpos.setStart(node,offset);
        setpos.collapse(true);
        set.removeAllRanges();
        set.addRange(setpos);
        tag.focus()
    },1000), []);
    
    function c(event){
        try{

        
        event.currentTarget.selectionStart = 0 
    event.currentTarget.selectionEnd = 0
        var parts = event.currentTarget.textContent.split(" ");
        parts.forEach((element,i) => {
           if(r.test(element)){
            parts[i] = <><span style={{color:"red",cursor:"pointer"}} key={i}>{parts[i]}</span>&nbsp;</>;     
           }else{
               parts[i] = " "+parts[i]+" "
           } 
        });
        parts.join(" ")
        handler(parts);
    }catch(e){
        console.error('input error',e);
    }
    }
    return (
        <div
        ref={ref}
        style={{backgroundColor:"#ffffff",height:"400px",padding:"10px"}}
        id="editable"
        contentEditable="true"
        suppressContentEditableWarning="true"
        onInput={e=>c(e)}
      >{Input}
      </div>
       
    )
}





