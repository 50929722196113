import React , {useContext, useEffect,useState} from 'react'
import  TwitterIcon from 'mdi-react/TwitterIcon'

import  HomeIcon from 'mdi-react/HomeAccountIcon'
import  HashtagIcon from 'mdi-react/HashtagIcon'
import  NotificationsIcon from 'mdi-react/BellOutlineIcon'
import  MessagesIcon from 'mdi-react/EmailOutlineIcon'
import  BookmarksIcon from 'mdi-react/BookmarkOutlineIcon'
import  ListIcon from 'mdi-react/FileDocumentBoxOutlineIcon'
import  ProfileIcon from 'mdi-react/AccountOutlineIcon'
import  MoreIcon from 'mdi-react/MoreCircleIcon'
import DownIcon from "mdi-react/ChevronDownIcon"

import {AiOutlineMessage,AiOutlineFormatPainter} from 'react-icons/ai'
import {GiElectric} from 'react-icons/gi'
import {BsBoxArrowUpRight} from 'react-icons/bs'
import {SiGoogleanalytics} from 'react-icons/si'
import {FiSettings} from 'react-icons/fi'
import {IoSettingsOutline} from 'react-icons/io5'

import {BiHelpCircle} from 'react-icons/bi'
import {FaRegKeyboard} from 'react-icons/fa'

import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const SidebarContianer = styled.div`
    height:100vh;
    font-family:sans-serif;
    padding-right:20px;
    position:sticky;
    top:0px;
    display: flex;
    flex-direction: column;
`

const SidebarOptionList = styled.ul`
    padding:0;
    margin: 0;
    margin-left:-10px;
    list-style: none;
    position:relative;
`;

const SidebarOptionListItem = styled.li`
    border-radius:50px;
    width: min-content;
    display: flex;
    align-items: center;
    position: relative;
    a{
    padding:10px 10px 5px 10px;

        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-weight: bold;
        color:${p=>p.theme.text};
        text-decoration: none;
        .icon{
            svg{
                transform: scale(1.2);
            }
        }
        .link{
            font-size:1.2rem;
            margin-left:20px;
        }

        &.active{
            color:#1496f3;
            svg{
                fill:#1496f3;
            }
        }

    }
    
&:hover{
    background-color:#1496f31f;
    a{
        color:#1496f3;
        svg{
            fill:#1496f3;
        }
    }
}

`;


const LogoMain = styled.span`
    margin-top:10px;
    margin-bottom:20px;
    svg{
        fill:${props=>props.theme.iconMain};
        transform:scale(1.5);
    }
`;

const TweetButtonMain = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    background-color:#1495f3;
    padding:15px 50px 10px 50px;
    width:200px;
    font-size:1rem;
    border-radius:50px;
    font-weight: bolder;
    cursor: pointer; 
    margin-left:-10px;
    margin-top:10px;
    &:hover{
        background-color: darken($color: #1495f3, $amount:5);
    }
    a{
        color: #ffffff;
        text-decoration: none;
    }



`;

const UserActionCTA = styled.div`
    margin-top: auto;
    margin-left:-10px;
    margin-bottom:20px;
    display: flex;
    align-items:center;
    padding:0.5rem .5rem;
    border-radius:50px;
    cursor: pointer;
    &:hover{
        background-color:#1496f31f;
        
    }

`; 

const UserImageAvatar = styled.span`
    width:40px;
    height:40px;
    overflow: hidden;
    border-radius:50px;
    img{
        object-fit: cover;
    }

`;

const UserInfo = styled.div`
    margin-left:10px;
    display: flex;
    flex-direction: column;
    & span:nth-child(1){
        color: #ffffff;
        font-weight: bold;
        font-size:1rem;
    }
    & span:nth-child(2){
        color: #cccccc;
        font-size:1rem;
        font-weight: bold;


    }

`;

const IconWrapper = styled.span`
    margin-left: auto;
    svg{
        fill:#ffffff;
    }
`;

const MoreOptionList = styled(SidebarOptionList)`
    position:absolute;
    background-color:${props=>props.theme.bgColor};
    box-shadow:0px 0px 10px 0px ${props=>props.theme.modalShadow};
    bottom:0px;
    width:200px;
`;
const MoreOptionItem = styled(SidebarOptionListItem)`
    width:100%;
    border-radius:0px;
    a{
        width:100%;
        display:flex;
        font-size:0.9rem;
        padding:13px 10px;
        font-weight:normal;
        svg{
            font-size:1.2rem;
        }
        span{
            margin-left:10px;
        }
    }
    &:nth-child(4){
        border-bottom:1px solid ${props=>props.theme.feedOutline};
    }
    &:hover{
        background-color:${props=>props.theme.accentMute};
        a{
            color:${props=>props.theme.text};
            svg{
                fill:${props=>props.theme.text};
            }
        }
    }


`;

const MoreOptionComponent = ({location})=>{


    const OPTIONS = [
        {link:"/vinodkaradiya/topics",Icon:AiOutlineMessage,title:"Topics"},
        {link:"/vinodkaradiya/moments",Icon:GiElectric,title:"Moments"},
        {link:"/ads",Icon:BsBoxArrowUpRight,title:"Twiitter Ads"},
        {link:"/analytics",Icon:SiGoogleanalytics,title:"Analytics"},
        {link:"/settings",Icon:IoSettingsOutline,title:"Setting and privacy"},
        {link:"/support",Icon:BiHelpCircle,title:"Help Center"},
        {link:"/i/display",Icon:AiOutlineFormatPainter,title:"Display",background:true},
        {link:"/i/keyboard_shortcuts",Icon:FaRegKeyboard,title:"Keyboard shortcuts"},
    ]

    return <MoreOptionList onClick={e=>e.stopPropagation()}>
        {
            OPTIONS.map((o,i)=>
            <MoreOptionItem key={`${i}-${o.title.length}`}>
               {
                  o.background ? <Link to={{ pathname:o.link,state: { background: location }}} >
                        <o.Icon/>
                        <span>{o.title}</span>
                    </Link>
                   : <Link to={{ pathname:o.link}} >
                        <o.Icon/>
                        <span>{o.title}</span>
                    </Link>
                   
               } 
                </MoreOptionItem>
            )
        }
    </MoreOptionList>
}





export const Sidebar = () => {
    const location = useLocation();
    

    return (
        <SidebarContianer>
                <SidebarLogo />
               <SidebarItemList location={location} />
               <SidebarTweetButton location={location} />
               <SidebarUserAction/>
        </SidebarContianer>
    )
}

const SidebarItemList = ({location,showOptions})=>{

   
    const [ActivePath, setActivePath] = useState(location.pathname)
    const [ShowDropdown, setShowDropdown] = useState(false);

    useEffect(()=>{
        let dropParant =  document.getElementById('MoreOptionID');
    
        document.addEventListener('click',(e)=>{
          setShowDropdown(dropParant?.contains(e.target))
        })
    
        return ()=>{
          document.removeEventListener('click',null)
        }
    },[])
    let username = "vinodkaradiya"

    const SidebarItem = [
        {link:"/home",name:"home",icon:<HomeIcon/>},
        {link:"/explore",name:"explore",icon:<HashtagIcon/>},
        {link:"/notifications",name:"Notifications",icon:<NotificationsIcon/>},
        {link:"/messages",name:"Messages",icon:<MessagesIcon/>},
        {link:"/bookmarks",name:"Bookmarks",icon:<BookmarksIcon/>},
        {link:"/list",name:"List",icon:<ListIcon/>},
        {link:`/${username}`,name:"Profile",icon:<ProfileIcon/>},
        {link:"/more",name:"More",icon:<MoreIcon/>,isButton:true},
    ]



    useEffect(() => {
        setActivePath(location.pathname)
        console.log('location',location);
    }, [location])


    return (
        <SidebarOptionList>

        {
            SidebarItem.map((l,i)=>
                l.isButton 
                    ? <SidebarOptionListItem id="MoreOptionID" key={i}>
                        <Link className={l.link===ActivePath?"active":""} onClick={()=>setShowDropdown(true)} >
                        <span className="icon">{l.icon}</span>
                        <span className="link">{l.name}</span>
                        </Link>
                    </SidebarOptionListItem> 
                :   <SidebarOptionListItem key={i}>
                        <Link className={l.link===ActivePath?"active":""}  to={l.link}>
                        <span className="icon">{l.icon}</span>
                        <span className="link">{l.name}</span>
                    </Link>
                    </SidebarOptionListItem>
                

            )
        }
    {ShowDropdown && <MoreOptionComponent location={location} />}
    </SidebarOptionList>
    )
}

const SidebarUserAction = ()=>{
    return (
        <UserActionCTA>
            <UserImageAvatar>
                 <img src="https://picsum.photos/seed/vinod/50/50" alt="img"/>
            </UserImageAvatar>
            <UserInfo>
            <span>Vinod</span>
            <span>@kradster</span>
            </UserInfo>
           <IconWrapper>
            <DownIcon/>
           </IconWrapper>
        </UserActionCTA>
    )
}

const SidebarTweetButton = ({location})=>{

    return (
        <TweetButtonMain>
            <Link to={{ pathname:'/compose/tweet',state: { background: location }}} >Tweet</Link>
        </TweetButtonMain>
    )
}

const SidebarLogo = ()=>{
    return (
        <LogoMain>
            <TwitterIcon/>
        </LogoMain>
    )
}