import React from 'react'
import Styled from 'styled-components'
import Feed from '../../component/Feed/Feed'
import Widgets from '../../component/Widgets/Widgets'
import {HomeLayout,FeedLayout,Heading} from '../Home/Home'
import { useContext } from 'react'
import { FeedContext } from '../../Context/FeedDataContext'


const Explore = () => {
    const {Tweets} = useContext(FeedContext)

    return (
        <HomeLayout>
                <FeedLayout>
                    <Heading>
                        Explore
                    </Heading>
                    {
                         Tweets.map((r,i)=>(
                            <Feed key={`r-${r.id}-${i}`} 
                            data={r}
                            />
                        ))
                    }
                   
                </FeedLayout>
               <Widgets showSearch={false} showWhats={false} showTop="20px" />
        </HomeLayout>
    )
}

export default Explore
